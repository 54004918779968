import React from 'react'
import './Footer.scss'
import {Link, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ActionCreators} from "../../actions";;

class Footer extends React.Component {
  constructor(props) {
    super(props);

  }


  render() {
    return (
      <section className="bg-light text-center mb-0">
        <div className="container">


        </div>
      </section>
    )
  }
}

const mapStatetoProps = state => (
  {}
)

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Footer));


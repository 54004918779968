/* eslint-disable */
import React from 'react'
import logo from "../../assets/images/halugen-logo.png";
import logowhite from "../../assets/images/halugen-logo-white.png";
import {Link} from "react-router-dom";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  render() {
    return (
      <header>
        {this.renderNav()}
        {this.renderSideBar()}
      </header>
    )
  }

  renderNav() {
    return (
      <header className='main-website'>
        <nav className={this.getNavClass()}>
          <div className="container px-1 px-md-3">
            <Link to="/" title="Logo" className="navbar-brand d-none d-sm-block logo">
              <div className='text-white font-weight-normal'>CLINICAL LAB</div>
            </Link>


            <div className="collapse navbar-collapse" id="halugen-nav">
              <div className="navbar-nav w-100 alt-font">
                <span className="menu-line"><i className="fa fa-angle-down" aria-hidden="true"></i></span>
              </div>
            </div>

            <div className='d-flex'>

            </div>

          </div>
        </nav>
      </header>
    )
  }

  isActiveNav(str) {
    if (window.location.href.includes(str))
      return "active";
    return ""
  }

  getCartColor() {
    if (this.props.format && this.props.format === 'transparent-light') {
      return 'text-white'
    }

    if (this.props.format && this.props.format === 'other') {
      return 'text-white'
    }
  }

  getNavClass() {
    let home = 'navbar navbar-top-default navbar-expand-lg navbar-simple navbar-dark nav-box-round';
    let light = 'navbar navbar-top-default navbar-expand-lg navbar-gradient nav-box-round';
    let other = 'navbar navbar-top-default navbar-expand-lg gradient-bg1 navbar-gradient nav-box-round';

    if (this.props.format && this.props.format === 'transparent-light') {
      return light
    }

    if (this.props.format && this.props.format === 'other') {
      return other
    }
    return home
  }


  getLogo() {
    let home = logo;
    let light = logowhite

    if (this.props.format && (this.props.format === 'transparent-light' || this.props.format === 'other')) {
      return light
    }

    return home
  }

  closeModal() {
    $(".navbar.navbar-right").toggleClass('show');
  }


  renderSideBar() {
    return (
      <>
        <div className="side-menu">
          <div className="inner-wrapper">
            <span className="btn-close" id="btn_sideNavClose"><i></i><i></i></span>
            <nav className="side-nav w-100">

              <ul className="navbar-nav">
                {this.props.user.isLoggedIn ? <li className="nav-item">
                  <Link className="nav-link" to="/admin">My Dashboard</Link>
                </li> : null}
                <li className="nav-item">
                  <Link className="nav-link" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/test">Psychedelics Test</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/how-it-works">How It Works</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">About Us</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">Contact</Link>
                </li>
                {/*<li className="nav-item">*/}
                {/*  <Link className="nav-link" to="/clinics">Clinics</Link>*/}
                {/*</li>*/}
              </ul>

              <div className="row justify-content-center mt-4">
                {this.props.user.isLoggedIn ?
                  <Link id={'btn-signout'} className="btn btn-signin mr-3" to="/" onClick={() => this.props.doLogout()}>Sign
                    Out</Link>
                  : <Link id={'btn-signup'} className="btn btn-signin mr-3" to="/sign-in">Sign In</Link>}
                <Link id={'btn-register'} className="btn btn-registerkit" to="/register">Register Kit</Link>
              </div>

            </nav>

            <div className="side-footer text-white w-100">
              <ul className="social-icons-simple">
                <li>
                  <a className="" href="https://www.facebook.com/halugensci" target="_blank">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a className="" href="https://www.instagram.com/halugensci" target="_blank">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a className="" href="https://twitter.com/halugensci" target="_blank">
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a className="" href="https://www.linkedin.com/company/halugensci" target="_blank">
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a className="" href="mailto:support@halugen.com" target="_blank">
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
              <p className="text-white">&copy; 2021 HaluGen Life Sciences.</p>
            </div>
          </div>
        </div>
        <a id="close_side_menu" href="javascript:void(0);"></a></>
    )
  }
}

export default Header;

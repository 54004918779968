import React from "react";
import {Link} from "react-router-dom";
import Helmet from "react-helmet";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: undefined
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  submitForm() {

    this.props.forgotPassword(this.state.email).then(res => {
      this.setState({email: ' '})
      alert('An email has been sent with instructions on how to reset your password.');
    }).catch(err => {
      alert('Something went wrong, please try again!');
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.submitForm()
  }

  renderMetaTags() {
    return (
      <>
        <Helmet>
          <title>Forgot Password | HaluGen Life Sciences</title>

          <meta itemProp="name" content="Forgot Password | HaluGen Life Sciences"/>
          <meta itemProp="description" content="Forgot Password for HaluGen Life Sciences."/>
          <meta itemProp="image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="description" content="Forgot Password for HaluGen Life Sciences."/>
          <meta property="og:title" content="Forgot Password | HaluGen Life Sciences"/>
          <meta property="og:description" content="Forgot Password for HaluGen Life Sciences."/>
          <meta property="og:url" content="https://www.halugen.com/forgot-password.html"/>
          <meta property="og:type" content="website"/>
          <meta property="og:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>
          <meta name="twitter:title" content="Forgot Password | HaluGen Life Sciences"/>
          <meta name="twitter:description" content="Forgot Password for HaluGen Life Sciences."/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:image" content="https://www.halugen.com/images/halugen-social-logo.png"/>

        </Helmet>
      </>
    )
  }

  render() {
    return (
      <>
        {this.renderMetaTags()}
        <section id="forgotpassword">
          <div class="container">
            <section>
              <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-10 col-xs-12 mx-auto my-4">
                  <h2 class="alt-color text-center mb-3">Reset Password</h2>
                  <p class="text-center mb-4">Please enter the email associated with your account to reset your password.</p>


                  <form onSubmit={this.handleSubmit}>
                    <div class="form-label-group px-1">
                      <input placeholder="Email" class="form-control" required="required"
                             value={this.state.email}
                             onChange={(e) => {
                               this.setState({email: e.target.value})
                             }}
                             aria-label="Email" type="email" name="email" id="email"/>
                    </div>

                    <fieldset name="fsaction" id="fsaction">
                      <div class="actions text-center">
                        <input name="commit" value="Send reset email" type='submit'
                               class="btn btn-large btn-gradient mt-3" data-disable-with="Forgot password"/>
                      </div>
                    </fieldset>

                  </form>

                  <p class="text-center mt-3">Remember your password? <Link to="/sign-in">Sign in</Link>
                  </p>
                </div>
              </div>
            </section>
          </div>
        </section>
      </>
    )
  }
}

export default ForgotPassword

import queryString from 'query-string'

export function isNullOrUndefined(obj) {
  try {
    if (obj === null || obj === undefined)
      return true;
    else
      return false;
  } catch (e) {
    return true;
  }
}

export function isNullOrUndefinedOrEmpty(obj) {
  if (obj === null || obj === undefined)
    return true;
  else {
    if (obj === '')
      return true

    if (Array.isArray(obj) && obj.length === 0)
      return true;

    if (obj instanceof Object && Object.keys(obj).length === 0) {
      return true;
    }
  }
  return false;
}

export function isNotNullOrUndefinedAndTrue(obj) {
  if (obj === null || obj === undefined)
    return false;
  else {
    if (obj) {
      return true
    } else {
      return false;
    }
  }
  return false;
}

export function capitalizeFLetter(obj) {
  let x = '';
  if (!isNullOrUndefined(obj) && !isNullOrUndefined(obj[0])) {
    try {
      obj = obj.toLowerCase();
      x = obj.replace(/^./, obj[0].toUpperCase());
    } catch (e) {
    }
  }
  return x;
}

export const addQuery = (props, key, value) => {
  let pathname = props.location.pathname;
  const values = queryString.parse(props.location.search);

  if (isNullOrUndefined(value) || value === 'undefined') {
    delete values[key]
  } else {
    values[key] = value
  }

  props.history.push({
    pathname: pathname,
    search: queryString.stringify(values)
  });
};
export const addQueryMap = (props, map) => {
  let pathname = props.location.pathname;
  const values = queryString.parse(props.location.search);
  if (!isNullOrUndefined(map)) {
    map.forEach((value, key) => {
      if (isNullOrUndefined(value) || value === 'undefined') {
        delete values[key]
      } else {
        values[key] = value
      }
    });
    let searchParams = queryString.stringify(values);

    props.history.push({
      pathname: pathname,
      search: searchParams
    });
  }
};


export function validateEmail(mail) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}

export function getBirthElement(birth_date, type) {
  if (isNullOrUndefined(birth_date) || isNullOrUndefined(type)) {
    return undefined
  }

  const strArr = birth_date.split('-');

  switch (type) {
    case 'MONTH':
      var res = strArr[1];
      if (res.length === 1) {
        res = '0' + res;
      }
      return res;
      break;
    case 'DAY':
      var res = strArr[2];
      if (res.length === 1) {
        res = '0' + res;
      }
      return res;
      break;
    case 'YEAR':
      return strArr[0];
      break;
    default:
      break;
  }
}

export const getKey = (obj, val) => Object.keys(obj).find(key => obj[key] === val);

export const toAdminDateFormat = (date) => {
  if (isNullOrUndefined(date)) {
    return '';
  }
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  return date.toLocaleDateString("en-US", options);
}

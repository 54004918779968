import {bfetch} from "../lib/http";
import {
  ADMIN_ATTACH_SWAB_KITS,
  ADMIN_CREATE_SWAB_KITS,
  ADMIN_CREATE_TESTS_RESULTS,
  ADMIN_DELETE_SWAB_KITS,
  ADMIN_DETACH_SWAB_KITS,
  ADMIN_FETCH_ALL_SWAB_KITS,
  ADMIN_FETCH_TESTS_RESULTS,
  ADMIN_FETCH_USER_DETAILS,
  ADMIN_UPDATE_TESTS_RESULTS,
  ADMIN_UPDATE_USER_PROFILE,
  CUSTOMER_DETAIL_DELETE_URL,
  ADMIN_FETCH_ALL_TRIALS
} from "../lib/constants";
import {doRegisterSwabKitSuccess} from "./swab-kits";
import {isNullOrUndefinedOrEmpty} from "../lib/helper";

export const fetchAllResults = (testType) => {
  let params = '?page=0&size=100000';
  if (!isNullOrUndefinedOrEmpty(testType)) {
    params += '&testType=' + testType;
  }

  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_FETCH_TESTS_RESULTS + params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const fetchAllSwabKits = () => {
  let params = '?page=0&size=100000';
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_FETCH_TESTS_RESULTS + params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const updateUserTestResults = (modal) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_UPDATE_TESTS_RESULTS,
        {
          method: 'PATCH',
          body: modal
        }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const fetchUserDetails = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_FETCH_USER_DETAILS + '?page=0&size=25').then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const detachSwabKit = (testId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_DETACH_SWAB_KITS, {
        method: 'PATCH',
        body: testId
      }).then(res => {
        dispatch(doRegisterSwabKitSuccess(res))
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const attachSwabKit = (swabKitId, userId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_ATTACH_SWAB_KITS, {
        method: 'PATCH',
        body: {swabKitId, userId}
      }).then(res => {
        dispatch(doRegisterSwabKitSuccess(res))
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const updateUserProfileAdmin = (payload) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_UPDATE_USER_PROFILE,
        {
          method: 'PATCH',
          body: payload
        }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const createNewTestAdmin = (payload) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_CREATE_TESTS_RESULTS,
        {
          method: 'POST',
          body: payload
        }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}


export const createSwabKitId = (swabKitId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_CREATE_SWAB_KITS, {
        method: 'POST',
        body: {identifier: swabKitId, isRegistered: false}
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const deleteSwabKitId = (swabKitId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_DELETE_SWAB_KITS + '/' + swabKitId, {
          method: 'DELETE'
        }
      ).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const deleteCustomerDetails = (customerId) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(CUSTOMER_DETAIL_DELETE_URL + '/' + customerId, {
          method: 'DELETE'
        }
      ).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const fetchTrials = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(ADMIN_FETCH_ALL_TRIALS).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

import React from "react";
import {Link} from "react-router-dom";

class AdminHeader extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>

        <header className="gradient-bg1">
          <div className="container mb-4">
            <nav className="navbar navbar-dark navbar-expand halugen-nav">
              <div className="container-fluid">
                <Link className="navbar-brand d-none d-sm-block logo" to="/admin">
                  <img src={require('../../../assets/images/halugen-logo-white.png')}/>
                </Link>
                <Link className="navbar-brand d-sm-none logo" to="/admin">
                  <img src={require('../../../assets/images/halugen-square-logo-white.png')}/>
                </Link>
                <button data-toggle="collapse" className="navbar-toggler" data-target="#navcol-1" style={{borderStyle: 'none'}}><span
                  className="sr-only">Toggle navigation</span><span className="navbar-toggler-icon" style={{borderColor: 'rgba(255,255,255,0)'}}/>
                </button>
                <div className="collapse navbar-collapse m-auto" id="navcol-1">
                  <ul className="nav navbar-nav mx-auto">
                    <li className="nav-item"><Link className="nav-link active" to="/admin">Clinical</Link></li>
                  </ul>
                  <ul className="nav navbar-nav">
                    <li className="nav-item mx-auto">
                      <div className="nav-item dropdown">
                        <a className="active text-white" data-toggle="dropdown" aria-expanded="false" href="#">
                          <i className="fa fa-user-circle nav-user-icon" style={{fontSize: '24px'}}/>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right fade" id="user-dropdown">
                          <Link to='/' className="dropdown-item" onClick={() => this.handleSignOut()}>Sign Out</Link>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>
      </div>
    )
  }

  handleSignOut() {
    this.props.doLogout();
  }

}
export default AdminHeader

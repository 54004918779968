import * as types from './types'
import {bfetch} from "../lib/http";
import {
  ACCOUNT_URL,
  CHANGE_PASSWORD,
  CUSTOMER_DETAIL_PUT_URL,
  CUSTOMER_DETAIL_URL,
  FORGOT_PASSWORD_URL,
  SEND_NOTIFICATION_EMAIL_URL,
  GET_SHOPIFY_PRODUCT,
  LOGIN_URL,
  REGISTER_URL,
  RESET_PASSWORD,
  CHANGE_USER_EMAIL,
  STORAGE_AUTH_TOKEN_KEY
} from '../lib/constants'
import {localStorageSet} from "../lib/localStorage-helper";
import {isNullOrUndefined} from "../lib/helper";
import {PASSWORD_PROTECTED_PAGE} from "./types";

export const doLogin = (username, password, rememberMe = false) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(LOGIN_URL, {
        method: 'POST',
        body: {username, password, rememberMe}
      }).then(res_jwt => {
        dispatch(dispatchSaveUserAccessToken(res_jwt));
        bfetch(ACCOUNT_URL).then(res => {
          bfetch(CUSTOMER_DETAIL_URL).then(res_cus => {
            dispatch(doLoginSuccess({res, res_jwt, res_cus}))
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        }).catch(err => {
          reject(err)
        })
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const doLoginBegin = payload => {
  return {
    type: types.LOGIN_BEGIN,
    payload,
  }
}

export const doLoginSuccess = response => {
  return {
    type: types.LOGIN_SUCCESS,
    payload: {response},
  }
}

export const doLoginFailure = error => {
  return {
    type: types.LOGIN_FAILURE,
    payload: error,
  }
}

export function dispatchSaveUserAccessToken(data, saveToStorage = true) {
  if (saveToStorage) {
    try {
      localStorageSet(STORAGE_AUTH_TOKEN_KEY, JSON.stringify(data));
    } catch (error) {
      //console.log('Local Storage error: ' + error.message);
    }
  }
  return {
    type: types.SAVE_USER_ACCESS_TOKEN,
    data
  };
}


export const doLogout = () => {
  return async dispatch => {
    dispatch(doLogoutBegin)
    dispatch(doLogoutSuccess())
  }
}

export const doLogoutBegin = payload => {
  return {
    type: types.LOGOUT_BEGIN,
    payload,
  }
}

export const doLogoutSuccess = () => {
  return {
    type: types.LOGOUT_SUCCESS
  }
}

export const doLogoutFailure = error => {
  return {
    type: types.LOGOUT_FAILURE,
    error
  }
}

export const registerUser = (email, password, fname = null, lname = null, country, region, birthDate, gender, promotionalEmail) => {
  const payloadObj = {
    email, login: email, langkey: 'en', password, country, region, dob: birthDate, promotionalEmail: promotionalEmail,
    firstName: fname, lastName: lname, gender
  }
  return async dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(registerUserBegin(payloadObj))
      bfetch(REGISTER_URL, {
        method: 'POST',
        body: payloadObj
      }).then(res => {
        dispatch(registerUserSuccess({res, payloadObj}))
        dispatch(doLogin(email, password)).then(loginRes => {
          resolve(res)
        }).catch(err => {
          reject(err);
        })
      }).catch(err => {
        dispatch(registerUserFailure({err}))
        reject(err);
      })
    })
  }
}

export const registerUserBegin = userInfo => {
  return {
    type: types.REGISTER_USER_BEGIN,
    payload: userInfo
  }
}

export const registerUserEnd = userInfo => {
  return {
    type: types.REGISTER_USER_END,
    payload: userInfo
  }
}

export const registerUserSuccess = payload => {
  return {
    type: types.REGISTER_USER_SUCCESS,
    payload
  }
}

export const registerUserFailure = error => {
  return {
    type: types.REGISTER_USER_FAILURE,
    payload: error
  }
}

export const sendNotficationEmail = (email, test) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(SEND_NOTIFICATION_EMAIL_URL.replace('${email}', encodeURIComponent(email)).replace('${test}', encodeURIComponent(test)), {
        method: 'GET'
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const forgotPassword = (email) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(FORGOT_PASSWORD_URL, {
        method: 'POST',
        body: email
      }).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const setAgeGateModalChecked = () => {
  return async dispatch => {
    dispatch(AgeGateCheckedSuccessful({}))
  }
}

export const AgeGateCheckedSuccessful = userInfo => {
  return {
    type: types.AGE_GATE_VERIFICATION_SUCCESS
  }
}

export const doAutoLoginBegin = payload => {
  return {
    type: types.AUTO_LOGIN_BEGIN,
    payload,
  }
}

export const doAutoLoginSuccess = response => {
  return {
    type: types.AUTO_LOGIN_SUCCESS,
    payload: {response},
  }
}

export const doAutoLoginFailure = error => {
  return {
    type: types.AUTO_LOGIN_FAILURE,
    payload: error,
  }
}

export const navigateToLoginScreen = () => {
  return {
    type: types.NAVIGATE_TO_LOGIN_SCREEN
  };
};


export const statupAcknowledge = () => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch(doStartupBegin())
      const uid = getState().LoginStatus.userProfile.id
      if (!isNullOrUndefined(uid))
        bfetch(ACCOUNT_URL).then(res => {
          bfetch(CUSTOMER_DETAIL_URL).then(res_cus => {
            dispatch(doLoginSuccess({res, res_jwt: {}, res_cus}))
            resolve(res)
          }).catch(err => {
            reject(err)
          })
        }).catch(err => {
          reject(err)
        })

    })
  }
}



export const doStartupBegin = () => {
  return {
    type: types.STARTUP_BEGIN
  };
};


export const submitCustomerDetails = (customerDetails) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(CUSTOMER_DETAIL_PUT_URL, {
        method: 'PUT',
        body: customerDetails
      }).then(res => {
        dispatch(doCustomerDetailsSuccess({res, customerDetails}))
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const doCustomerDetailsSuccess = (res) => {
  return {
    type: types.CUSTOMER_DETAILS_UPDATE,
    res
  };
};
export const submitChangePassword = (payload) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(CHANGE_PASSWORD, {
        method: 'POST',
        body: payload
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const resetPassword = (newPassword, key) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(RESET_PASSWORD, {
        method: 'POST',
        body: {newPassword, key}
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const changeUserEmail = (id, email) => {
  return async (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      bfetch(CHANGE_USER_EMAIL, {
        method: 'PATCH',
        body: {id, email}
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err)
      })
    })
  }
}

export const correctPasswordProtectedPage = () => {
  return {
    type: types.PASSWORD_PROTECTED_PAGE
  };
};

export const STORAGE_AUTH_TOKEN_KEY = '@CLINICAL_LAB_auth_token';

function createApiCall(postUrl) {
  return process.env.REACT_APP_API_REST_ENDPOINT + postUrl;
}

export const FORGOT_PASSWORD_URL =createApiCall('/api/account/reset-password/init');
export const SEND_NOTIFICATION_EMAIL_URL =createApiCall('/api/test/send-notification/${email}/${test}');
export const LOGIN_URL = createApiCall('/api/authenticate');
export const ACCOUNT_URL = createApiCall('/api/account');
export const CHANGE_PASSWORD = createApiCall('/api/account/change-password');
export const RESET_PASSWORD = createApiCall('/api/account/reset-password/finish');
export const CHANGE_USER_EMAIL = createApiCall('/api/admin/users/user-details');
export const CUSTOMER_DETAIL_URL = createApiCall('/api/customer-details/user');
export const CUSTOMER_DETAIL_PUT_URL = createApiCall('/api/customer-details/user');
export const CUSTOMER_DETAIL_DELETE_URL = createApiCall('/api/customer-details');
export const SUBMIT_SURVEY = createApiCall('/api/surveys');
export const REGISTER_SWAB_KIT = createApiCall('/api/psychedelic-test-results/register');
export const DELETE_SURVEY = createApiCall('/api/surveys/user/${id}');
export const REGISTER_URL = createApiCall('/api/register');
export const GET_SHOPIFY_PRODUCT = createApiCall('/api/shopify-product');
export const GET_SHOPIFY_CHECKOUT_URL = createApiCall('/api/shopify-product/checkout');
export const SUBMIT_CONTACT_US_FORM = createApiCall('/api/contact-us');

// ADMIN
export const ADMIN_FETCH_ALL_TRIALS = createApiCall('/api/trials');
export const ADMIN_FETCH_ALL_SWAB_KITS = createApiCall('/api/swab-kits');
export const ADMIN_CREATE_SWAB_KITS = createApiCall('/api/swab-kits');
export const ADMIN_DELETE_SWAB_KITS = createApiCall('/api/swab-kits');

export const ADMIN_DETACH_SWAB_KITS = createApiCall('/api/psychedelic-test-results/detach');
export const ADMIN_ATTACH_SWAB_KITS = createApiCall('/api/psychedelic-test-results/attach');
export const ADMIN_CREATE_TESTS_RESULTS = createApiCall('/api/psychedelic-test-results');
export const ADMIN_FETCH_TESTS_RESULTS = createApiCall('/api/test');
export const ADMIN_UPDATE_TESTS_RESULTS = createApiCall('/api/test/admin');

export const ADMIN_FETCH_USER_DETAILS = createApiCall('/api/customer-details-and-counts');

export const ADMIN_UPDATE_USER_PROFILE = createApiCall('/api/admin/user-details');


export const SURVEY_TYPE = Object.freeze({
  "BIPOLAR": 'BIPOLAR',
  "PSYCHOSIS": 'PSYCHOSIS',
  "FAMILY_HISTORY": 'FAMILY_HISTORY',
  "LIFE_STYLE_ALCOHOL": 'LIFE_STYLE_ALCOHOL',
  "LIFE_STYLE_DRUGS": 'LIFE_STYLE_DRUGS',
});

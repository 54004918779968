import React from 'react'
import './App.scss';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom'
import ScrollToTop from "./components/ScrollToTop";
import {store} from "./store/store";
import {Provider} from "react-redux";
import StartupContainer from "./pages/Startup/StartupContainer";
import MainWebsiteRoute from "./layouts/MainWebsiteRoute";
import AdminRoute from "./layouts/AdminRoute";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import MainWebsiteOtherRoute from "./layouts/MainWebsiteOtherRoute";
import SignInContainer from "./pages/Auth/SignIn/SignInContainer";
import ForgotPasswordContainer from "./pages/Auth/ForgotPassword/ForgotPasswordContainer";

const AdminHomeContainer = React.lazy(() => import('./pages/Admin/AdminHome/AdminHomeContainer'))

export const APP_STARTED = true;

function App() {
  return (
    <Provider store={store}>
      <ToastContainer/>
      <BrowserRouter>
        <StartupContainer/>
        <ScrollToTop/>
        <div className="App">
          <Main></Main>
        </div>
      </BrowserRouter>
    </Provider>
  );
}

function Main() {
  return (
    <React.Suspense fallback={
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
      </div>
    }>
      <main>
        <Switch>
          <MainWebsiteRoute exact path='/' component={AdminHomeContainer}/>
          <MainWebsiteOtherRoute exact path='/sign-in' component={SignInContainer}/>
          <MainWebsiteOtherRoute exact path='/forgot-password' component={ForgotPasswordContainer}/>
          <AdminRoute exact path='/admin' component={AdminHomeContainer}/>

          <Route>
            <Redirect to={'/'}/>
          </Route>
        </Switch>
      </main>
    </React.Suspense>
  );
}

export default App;
